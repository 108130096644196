/* You can add global styles to this file, and also import other style files */
/*TODO - change to scss*/

body {
  -webkit-text-size-adjust: 100%;
}
/*Pre-loader classes*/
.main-preloader-container {
  width: 100%;
  height: 100%;
  position: absolute;
  /*left: 50%;
    top: 50%;*/
  /*margin-top: -28px;
    margin-left: -28px;*/
  background-color: #1A1A24;
}

.preloader-image-container {
  top: 50%;
  left: 45%;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  /*border: 2px solid #fff;*/
  z-index: 101;
  position: relative;
  margin-top: 10px;
  margin-left: 8px;
  background-color: #1A1A24;
}

.flexstart {
  justify-content: flex-start;
}

.preloader-image-container img {
  margin: 8px 8px;
  width: 100%;
}

.preloader-text {
  font-family: 'Noto Sans', sans-serif;
  width: 90px;
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: 28px;
  margin-left: -40px;
  color: #333;
}

/*.preloader-text:not(.error):after {
        overflow: hidden;
        display: inline-block;
        vertical-align: bottom;
        -webkit-animation: preloader-ellipsis-loader steps(4,end) 1.1s infinite;
        -moz-animation: preloader-ellipsis-loader steps(4,end) 1.1s infinite;
        -o-animation: preloader-ellipsis-loader steps(4,end) 1.1s infinite;
        animation: preloader-ellipsis-loader steps(4,end) 1.1s infinite;
        content: "\2026";
        width: 0px;
    }*/

@keyframes preloader-ellipsis-loader {
  to {
    width: 1.25em;
  }
}

@-webkit-keyframes preloader-ellipsis-loader {
  to {
    width: 1.25em;
  }
}

@-moz-keyframes preloader-ellipsis-loader {
  to {
    width: 1.25em;
  }
}

@-o-keyframes preloader-ellipsis-loader {
  to {
    width: 1.25em;
  }
}

.main-preloader {
  position: absolute;
  top: 50%;
  left: 45%;
  width: 80px;
  height: 80px;
  border-radius: 100%;
  border: 3px solid;
  border-top-color: #FFFFFF;
  border-bottom-color: #1A1A24;
  border-left-color: #1A1A24;
  border-right-color: #1A1A24;
  -webkit-animation: mainpreloader 1s ease-in-out infinite;
  -moz-animation: mainpreloader 1s ease-in-out infinite;
  -o-animation: mainpreloader 1s ease-in-out infinite;
  animation: mainpreloader 1s ease-in-out infinite;
  z-index: 100;
}

@-moz-keyframes mainpreloader {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes mainpreloader {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@-o-keyframes mainpreloader {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes mainpreloader {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.preloader-error {
  width: 100%;
  display: inline-block;
  position: absolute;
  top: 50%;
  margin-top: 28px;
  text-align: center;
  color: #333;
}

.main-preloader-stopped {
  animation: none !important;
}

.preloader-text.error {
  width: 555px;
  margin-left: -250px;
}

.preloader-text.error span {
  color: #564CAF;
  cursor: pointer;
}
/*Pre-loader classes end*/



/*button styling based on style guid start*/
.btn {
  line-height: 1.5em;
  min-width: 100px;
  border-radius: 0;
  /*border-right: solid 1px #333333;
    border-bottom: solid 1px #333333;*/
  box-shadow: none;
  background-color: #c0c0c0;
  min-height: 38px;
  height: 42px;
  font-size: 16px;
}

.btn:hover {
  border-right: solid 1px transparent;
  border-bottom: solid 1px transparent;
}

.btn-ey-default {
  color: #333333;
  background-color: #FEE600;
  border-color: #FEE600;
  /*border-right: solid 1px #c0c0c0;
    border-bottom: solid 1px #c0c0c0;*/
  min-height: 38px;
}

.btn-ey-default:hover,
.btn-ey-default:focus,
.btn-ey-default:active,
.open .dropdown-toggle.btn-ey-default {
  color: #333333;
  background-color: #FEE600;
  border-color: #FEE600;
}

.btn-ey-default-gray {
  color: #ffffff;
  background-color: #C0C0C0;
  border-color: #C0C0C0;
  font-size: 13px;
  min-height: 38px;
}

.btn-outline {
  background: transparent;
  border: 1px solid #2E2E38;
  line-height: 1em;
  font-size: 16px;
  font-family: 'EYInterState-Bold',sans-serif;
  padding: 0.575rem 2.5rem;
  transition: all;
  cursor: pointer;
  font-weight: 700;
}


.btn-outline:hover, .btn-outline:focus {
  background: black;
  color: white;
  transition: all;
}

.btn-outline:hover .fa, .btn-outline:focus .fa {
  color: white;
}

.btn-outline:disabled {
  opacity: 0.65;
  cursor: not-allowed;
  background: transparent;
  border: 1px solid #2E2E38;
  color: graytext;
}

.or {
  padding: 0 15px;
  font-size: 16px;
}

.btn-black-back {
  color: #000;
  display: inline-block;
  cursor: pointer;
  font-size: 16px;
  font-family: 'EYInterState-Bold',sans-serif;
  transition: all;
}

.btn-black-back:hover, .btn-black-back:focus {
  text-decoration: underline;
}

.btn-ey-default-gray:hover, .btn-ey-default-gray:focus, .btn-ey-default-gray:active, .open .dropdown-toggle.btn-ey-default-gray {
  color: #333333;
  background-color: #C0C0C0;
  border-color: #C0C0C0;
}

.btn-ey-dark-gray {
  color: #FFF;
  background-color: #666;
}

.btn-ey-dark-gray:hover,
.btn-ey-dark-gray:focus,
.btn-ey-dark-gray:active {
  color: #FFF;
  background-color: #666;
}

.btn-disabled {
  opacity: 0.65;
  cursor: not-allowed;
}


.btn-ey-clear {
  background: transparent;
  border: 1.2px solid #808080;
  padding: 7px 10px;
  text-align: left;
  cursor: pointer;
  font-size: 16px;
  font-family: 'EYInterState-Bold',sans-serif;
}
/*button styling based on style guide end*/


/*Dialog Modal styles start*/
.modal-body .whiteClose {
  padding: 0 10px;
  cursor: pointer;
  float: right;
}

.whiteClose {
  cursor: pointer;
}

.ey-dialog-modal .modal-content, .ey-alert-modal .modal-content, .ey-info-modal .modal-content {
  color: #5F5F5F;
  font-size: 14px;
  border-radius: 0px;
}

.ey-dialog-modal .modal-header, .ey-alert-modal .modal-header, .ey-info-modal .modal-header {
  border: none;
}

.ey-dialog-modal .modal-header h1, .ey-alert-modal .modal-header h1, .ey-info-modal .modal-header h1 {
  font-size: 14px;
  font-weight: bold;
}

.ey-dialog-modal .modal-footer, .ey-alert-modal .modal-footer, .ey-info-modal .modal-footer {
  border: none;
  text-align: left;
}

.ey-dialog-modal .modal-header button, .ey-alert-modal .modal-header button, .ey-info-modal .modal-header button {
  background-color: transparent;
  filter: alpha(opacity=100);
  opacity: 1;
}

.ey-dialog-modal .modal-header button:hover, .ey-alert-modal .modal-header button:hover, .ey-info-modal .modal-header button:hover {
  background-color: transparent;
  filter: alpha(opacity=100);
  opacity: 1;
}

.ey-dialog-modal .modal-header button.close span, .ey-alert-modal .modal-header button.close span, .ey-info-modal .modal-header button.close span {
  color: #CCCCCC;
}

.ey-dialog-modal .modal-header button.close, .ey-alert-modal .modal-header button.close, .ey-info-modal .modal-header button.close {
  box-shadow: none;
}

.ey-info-modal {
  margin-top: 0px;
}

.ey-info-modal.modal {
  text-align: center;
  padding: 0 !important;
}

.ey-info-modal.modal:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px;
}

.ey-info-modal .modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  width: auto;
}

.ey-info-modal-default {
  /*width:600px;*/
}

.floating-dialog-close {
  position: relative;
  /*margin-top: -45px;*/
  /*color: #fff;*/
  z-index: 3;
  text-transform: uppercase;
  margin-right: -20px;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  padding: 5px 10px 5px 15px;
  font-family: EYInterState-Bold,sans-serif;
}

.floating-dialog-close .whiteClose {
  margin-top: -2px;
}



/*Dialog Modal styles end*/
/*Progress Bar start*/
/*.loading-body {
    overflow: hidden;
}

    .loading-body .progressbar-disabled-overlay, .loading-body .progressbar-progress-outer {
        display: block !important;
    }*/
.page-loading .page-transition {
  display: block !important;
}

.page-posting {
  overflow: hidden
}

.page-posting .page-posting-overlay-container {
  display: block !important;
}

.in-page-progressbar-container {
  height: 100%;
  width: 100%;
  min-height: 100px;
  background-color: #fff;
  opacity: .7;
  position: absolute;
  z-index: 1010;
  display: block;
  left: 0;
  top: 0;
}

.in-page-progressbar-container .in-page-progressbar {
  width: 100px;
  height: 100px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -50px;
  margin-left: -50px;
  border-radius: 50px;
  background-color: transparent;
  border: solid 3px transparent;
  border-top: solid 3px #FFEE00;
  border-right: solid 3px #FFEE00;
  border-bottom: solid 3px #FFEE00;
  /*box-shadow:inset 0 0 0 3px;*/
  -webkit-animation: inpageProgress 1s infinite ease-in-out;
  -moz-animation: inpageProgress 1s infinite ease-in-out;
  -o-animation: inpageProgress 1s infinite ease-in-out;
  animation: inpageProgress 1s infinite ease-in-out;
}

@-webkit-keyframes inpageProgress {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes inpageProgress {
  0% {
    -moz-transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(360deg);
  }
}

@-o-keyframes inpageProgress {
  0% {
    -o-transform: rotate(0deg);
  }

  100% {
    -o-transform: rotate(360deg);
  }
}

@keyframes inpageProgress {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
/*Progress Bar end*/
.clear-fix {
  clear: both;
}

.no-padding-column {
  padding-left: 0;
  padding-right: 0;
}

@media only screen and (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

@media only screen and (min-width: 1600px) {
  .container {
    width: 1420px;
  }
}

@media only screen and (min-width: 1900px) {
  .container {
    width: 1600px;
  }
}

/*
    EYV Velocity tooltip styles
*/

.eyv-tooltip-container {
  position: absolute;
  opacity: 0;
  z-index: 9050;
  padding: 20px;
  border-radius: 0px;
  /*border: solid 1px #000;*/
  background-color: #fee40a;
  /*width: 250px;
    height: 150px;*/
  width: 300px;
  /*height: 150px;*/
  height: auto;
  line-height: 21px;
  text-align: left;
  vertical-align: top;
  font-size: 16px;
  font-family: "EYInterState-Light", sans-serif;
  /*color: #F04C3E;*/
  box-shadow: 2px 2px 9px 0px rgba(221, 221, 221, 0.47);
  /*min-height: 52px;*/
}

.tooltip-show {
  opacity: 1;
  transition: opacity .15s linear;
}


.eyv-tooltip-container .eyv-callout {
  /*position: absolute;
        right: 20px;
        bottom: 0px;
        margin-bottom: -10px;
        width: 0;
        height: 0;
        border-top: solid 10px #c0c0c0;
        border-left: solid 10px transparent;
        border-right: solid 10px transparent;
        z-index: 9022;*/
  position: absolute;
  left: 100%;
  top: 50%;
  margin-top: -15px;
  width: 0;
  height: 0;
  border-left: solid 15px #fee40a;
  border-top: solid 15px transparent;
  border-bottom: solid 15px transparent;
  z-index: 9022;
}

/*.eyv-callout::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-left: solid 8px #fee40a;
    border-bottom: solid 8px transparent;
    border-top: solid 8px transparent;
    z-index: 9021;
    margin-top: -9px;
    margin-left: -10px;
}*/

.dnd-drag-start {
  /*-moz-transform: none !important;
    -webkit-transform: none !important;
    transform: none !important;*/
  opacity: 0.7;
  border: 1px dashed #000;
}

.dnd-drag-enter {
  opacity: 0.7;
  border: 1px dashed #000 !important;
}

.dnd-drag-over {
  border: 1px dashed #000 !important;
}

.dnd-sortable-drag {
  /*-moz-transform: none !important;
    -webkit-transform: none !important;
    transform: none !important;*/
  opacity: 0.7;
  border: 1px dashed #000;
}

input::-ms-clear {
  display: none;
}

input::placeholder {
  opacity: 0.25;
  font-family: "EYInterState-Light";
}

input::-webkit-input-placeholder {
  opacity: 0.25;
  font-family: "EYInterState-Light";
}

input::-moz-placeholder {
  opacity: 0.25;
  font-family: "EYInterState-Light";
}

input::-ms-input-placeholder {
  opacity: 0.25;
  font-family: "EYInterState-Light";
}

input::-o-placeholder {
  opacity: 0.25;
  font-family: "EYInterState-Light";
}

.ad-container {
  width: 80% !important;
  max-width: 1300px;
  margin: 5%;
}

@media print {
  html, body {
    display: none; /* hide whole page */
  }
}

/*Bootstrap 4 changes*/

button {
  cursor: pointer;
}

a:not([href]):not([tabindex]) {
  color: #337ab7;
}

a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: #0056b3;
}

.form-control,.form-select {
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
}

.rowWithoutFlex {
  display: block;
}

.left-float {
  float: left !important
}

.close {
  float: right;
  font-size: 21px;
  font-weight: bold;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  filter: alpha(opacity=20);
  opacity: .2;
}

h1, .h1, h2, .h2, h3, .h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}

p {
  margin: 0 0 10px;
}

.popover-body {
  color: #ffffff;
}

.card {
  margin-bottom: 20px;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
}

.card-body {
  padding: 15px;
}

.card-msg {
  text-align: left;
  overflow: hidden;
  padding-left: 10px;
}

.card-text {
  color: #6C6C6C;
  font-size: 14px;
  padding-top: 8px;
}

.tooltip {
  line-height: 1.42857143;
  font-size: 12px;
}

@media (min-width: 576px) {
  .container {
    max-width: 100%;
  }
}

.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: none;
}

.colWithoutPadding {
  padding: 0;
}

.form-control:focus {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.page-link:focus {
  outline: 0;
  box-shadow: none;
}

.pagination-sm .page-link {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
}

.pagination {
  margin: 20px 0;
}

a, a:hover {
  color: #155CB4;
}
a:not([href]){
    text-decoration:none;
}
.privacy-container .main-container div ul {
  list-style: none;
}

.privacy-container .main-container div ol li ul li:before {
  content: "";
  border-color: transparent #000000;
  border-style: solid;
  border-width: 0.35em 0 0.35em 0.45em;
  display: block;
  height: 0;
  width: 0;
  left: -1em;
  top: 1.1em;
  position: relative;
}
.privacy-table{
  thead{
    background-color: orange;
  }
  tr,td,th{
    border: 1px solid black;
    border-collapse: collapse;
    text-align: center;
  }
  ul li{
    text-align: start;
  }
}
.privacy-head{
  font-weight: bold;
}
.privacy-text-underline{
  text-decoration: underline;
}
.ui-draggable, .ui-droppable {
  -ms-touch-action: none;
  touch-action: none;
}

.mCS-dark.mCSB_scrollTools .mCSB_draggerRail {
  display: none;
}

.mCSB_scrollTools.mCSB_scrollTools_vertical .mCSB_dragger .mCSB_dragger_bar {
  width: 8px !important;
}

.mCSB_scrollTools_vertical .mCSB_dragger {
  min-height: 100px !important;
}


.mCS-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #EBEBF0;
}

.mCS-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #C4C4CD !important;
}

/****** Resource Style *************/

.resource-container-max-height {
  /* max-height: 58vh; */
  min-height: auto;
  max-height: 115vh;
}


/****** End-Resource Style *********/
/*Disabling Styles for Locked 7 Driver instances*/
.disabledClick {
  pointer-events: none !important;
  cursor: default !important;
}

.readOnlyBarometer {
  opacity: 0.5;
}
/*End-Disabling Styles for Locked 7 Driver instances*/

/* MainContainer Aligment Style*/
.mc-pad-lr {
  padding: 0px 15px;
}
/* End MainContainer Aligment Style*/

//Bootstrap Update

.form-group {
  margin-bottom: 1rem;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}
.float-right {
  float: right !important;
}
a {
  text-decoration: none;
  background-color: transparent;
}
ol, ul {
  padding-left:40px;
}
.form-select:focus{
    box-shadow:none;
}
hr{
    opacity:1;
}
.close{
    border:0;
}

.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus{
    opacity:0.75;
    background-color:transparent;
}
.float-left {
  float: left !important;
}
.nav-tabs .nav-item {
  margin-bottom: -1px;
}
.ml-3, .mx-3 {
  margin-left: 1rem !important;
}
.ml-auto, .mx-auto {
  margin-left: auto !important;
}

.mr-auto, .mx-auto {
  margin-right: auto !important;
}
//ui-switch
.switch-small {
  width: 33px;
  height: 20px;
  border-radius: 20px;
}

.switch {
    background: #f00;
    border: 1px solid #dfdfdf;
    position: relative;
    display: inline-block;
    box-sizing: content-box;
    overflow: visible;
    padding: 0;
    margin: 0;
    cursor: pointer;
    box-shadow: rgb(223 223 223) 0 0 0 0 inset;
    transition: 0.3s ease-out all;
    -webkit-transition: 0.3s ease-out all;
}

.switch-small.checked small {
    left: 13px;
}

.switch-small small {
    width: 20px;
    height: 20px;
    background:rgb(255, 255, 255);
}

.switch-small small {
    border-radius: 100%;
    box-shadow: 0 1px 3px rgb(0 0 0 / 40%);
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.3s ease-out all;
    -webkit-transition: 0.3s ease-out all;
}

.switch-small small {
    font-size: 80%;
    font-weight: 400;
}

.tooltip-style-ac div {
    background-color: white !important;
    color: black !important;
    border: 1px solid black;
}

.tooltip-style-ac .tooltip-inner {
    background-color: #00cc00;
    opacity: 1;
}

.tooltip-style-ac.tooltip.bs-tooltip-top .arrow:before {
    border-top-color: white !important;
    border-right-color: #282836 !important;
    border-left-color: #282836 !important;
    margin-top: 0;
}

.tooltip-style-gp-details {
    top: 15px !important;
}

.people-picker-container {
    height: 36px;
    // border: solid 1px #CCCCCC;
    font-size: 15px;
    line-height: 30px;
    // font-family: arial;
    //  width:70%;
    input {
        padding-left: 10px;
        width: 100%;
        height: 36px;
    }
}

.filtered-user-list {
    padding: 5px 10px;
    cursor: pointer;
    line-height: 20px;

    .company {
        color: #747480;
        font-size: 14px;
        margin-left: 10px;
    }
}


.selection-holder {
    padding: 10px 0px;
    width: calc(66% - 5px);
    // border: solid 1px #CCCCCC;
    background-color: #FFFFFF;
    position: static;
    z-index: 999;
    -webkit-box-shadow: 0 5px 5px rgba(0, 0, 0, .5);
    box-shadow: 0 5px 5px rgba(0, 0, 0, .5);
    height: auto;
    font-size: 16px;

    .company {
        color: #747480;
        font-size: 14px;
        margin-left: 10px;
    }

    .mCSB_inside > .mCSB_container {
        margin-right: 10px;
    }
}

.preloader-holder {
    padding: 5px 0px;
    width: calc(66% - 5px);
    // border: solid 1px #CCCCCC;
    background-color: #FFFFFF;
    position: static;
    z-index: 999;
    text-align: center;
    -webkit-box-shadow: 0 5px 5px rgba(0, 0, 0, .5);
    box-shadow: 0 5px 5px rgba(0, 0, 0, .5);
}

.empty-text-holder {
    padding: 5px 0px;
    width: calc(66% - 5px);
    // border: solid 1px #CCCCCC;
    background-color: #FFFFFF;
    position: static;
    z-index: 999;
    text-align: center;
    -webkit-box-shadow: 0 5px 5px rgba(0, 0, 0, .5);
    box-shadow: 0 5px 5px rgba(0, 0, 0, .5);
}

.multiselect-selection-holder {
    width: 500px;
}

.multiselect-preloader-holder {
    width: 500px;
}

.multiselect-empty-text-holder {
    width: 500px;
}

.filtered-user-list {
    padding: 5px 10px;
    cursor: pointer;
    line-height: 20px;
}

.filtered-user-list:hover {
    background-color: #F6F6FA;
}

.user-text {
    padding: 0px 5px;
}

.delete-user {
    color: #000;
    text-decoration: none;
    cursor: pointer;
    background-color: transparent;
    -webkit-appearance: none;
    padding: 0;
    padding-left: 10px;
    font-size: 17px;
    font-weight: bold;
    line-height: 1;
    text-shadow: 0 1px 0 #fff;
    font-family: Arial;
    border: none;
}

.driver-assessment-container .desc-p p {
    margin-bottom: 0px;
}

.average-hide {
    .noUi-handle-average-lower, .noUi-handle-average-upper {
        display: none !important;
    }
}

.tooltip-label {
    text-align: left;
    padding-top: 2px;
    width: 90%;
    float: left;
}

.fop-quadrant .modal-dialog .modal-content .modal-body .quadrant-area .tooltiptext hr {
    border: 0.2px solid #DCDCDC !important;
    width: 100%;
    margin-top: 6px;
    clear: both;
}

.fop-quadrant .modal-dialog .modal-content .modal-body .quadrant-area .tooltiptext {
    width: 170px;
    background-color: white;
    color: black;
    text-align: center;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 120%;
    box-shadow: 0px 0px 8px 2px rgb(0 0 0 / 15%);
    font-size: 12px;
}

.tooltiptext {
    flex-wrap: wrap;
    min-width: 55px;
    display: flex !important;
    padding: 5px !important;
    padding-top: 3px !important;
}
